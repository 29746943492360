<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0" :id="'admin-name'">
          {{
            session.account.name.length > 30
              ? session.account.name.substr(0, 30) + " ..."
              : session.account.name
          }}
        </p>
        <b-tooltip
          v-if="session.account.name.length > 30"
          class="cursor-pointer"
          :target="'admin-name'"
        >
          {{ session.account.name }}
        </b-tooltip>
        <span class="user-status">{{ session.account.alias }}</span>
      </div>

      <b-avatar size="40" :src="avatar" variant="light-primary">
        <feather-icon v-if="!session.business.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <!--<b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>-->
    <!--<b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>-->
    <!--<b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>-->

    <!--<b-dropdown-divider />-->

    <!-- lsandoval: 17/01/2022 We change account settings with factura.green settings -->
    <!--<b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >-->
    <b-dropdown-item
      :to="{ name: 'fg-account-settings' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>{{ t("Settings") }}</span>
    </b-dropdown-item>
    <!--<b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>-->
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>{{ t("FAQ") }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      :to="{ name: 'pages-fg-manuals' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="BookOpenIcon" class="mr-50" />
      <span>{{ t("Manuals") }}</span>
    </b-dropdown-item>
    <!--
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>{{ t('FAQ') }}</span>
    </b-dropdown-item>-->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ t("Logout") }}</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BDropdown,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BTooltip,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BDropdown,
    BTooltip,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  mounted() {
    this.$root.$on("session-refresh", (message) => {
      console.info("UserDropdown on session-refresh");
      this.session = JSON.parse(localStorage.getItem("session"));
    });
  },
  computed: {
    avatar: function () {
      return this.session.business.avatar &&
        this.session.business.avatar !== "" &&
        this.session.business.avatar.startsWith("https")
        ? this.session.business.avatar + "?rand=" + Date.now()
        : "";
    },
  },
  setup() {
    /** lsandoval: 25/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils();

    return {
      t,
    };
  },
  data() {
    return {
      /** lsandoval: 25/01/2022 we use session instead of only userData */
      session: JSON.parse(localStorage.getItem("session")),
      avatarText,
    };
  },
  methods: {
    logout() {
      this.$http.post("/s/stop", {}).then((response) => {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

        // Remove userData from localStorage
        localStorage.removeItem("session");
        localStorage.removeItem("tokenBundle");

        // Reset ability
        this.$ability.update(initialAbility);

        // Redirect to login page
        this.$router.push({ name: "auth-login" });
      });
    },
  },
};
</script>
